@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");
body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
}
@desktop: ~"only screen and (min-width: 1025px) and (max-width: 4080px)";

.header {
  z-index: 999;
  // position: absolute;
  top: 0;
  background: white;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  border-bottom: 1px solid #cdcfce;
  // width: 100%;

}

.headerDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
  // padding-top: 18px;
  // padding-bottom: 18px;
  // margin-left: 16px;
  // border-bottom: 1px solid #cdcfce;
}
.headerTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #040e0c;
  margin-bottom: 0;
  margin-left: 16px;
}
.backImg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-left: 16px;

}
.headerTitle2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #040e0c;
  margin-bottom: 0;
  margin-left: 16px;

}

// @media @desktop {
//   .header {
//     z-index: 999;
//     position: absolute;
//     top: 0;
//     background: white;
//   }
//   .headerDiv {
//     margin: 0 25%;
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     cursor: pointer;
//     padding-top: 18px;
//     padding-bottom: 18px;
//     // margin-left: 16px;
//     border-bottom: 1px solid red;
//   }
// }
@primary-color: #278977;