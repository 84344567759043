.sign-up-form {
  font-size: 16px !important;
  button {
    width: 100%;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-form-item {
    margin-bottom: 4px;
  }
}

@primary-color: #278977;