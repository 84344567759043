@import "~antd/dist/antd.less";

@mobile: ~"only screen and (min-width: 320px) and (max-width: 767px)";
@tablet: ~"only screen and (min-width: 768px) and (max-width: 992px)";
@ipad: ~"only screen and (min-width: 993px) and (max-width: 1024px)";
@desktop: ~"only screen and (min-width: 1025px) and (max-width: 4080px)";

@media @mobile {
  .layout {
    margin: 0 16px;
    background: #fff;
    min-height: 100vh;
  }
  .header {
    z-index: 999;
    position: absolute;
    top: 0;
    background: white;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    margin-left: -18px;
    border-bottom: 1px solid #cdcfce;
    width: 100%;
  
  }
  .headerPost {
    z-index: 999;
    position: absolute;
    top: 0;
    background: white;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    margin-left: -18px;
    border-bottom: 1px solid #cdcfce;
    width: 100%;
}
.postDiv {
  background-color: #FFFFFF !important;

}
  .image-carousel {
    width: 100%;
    img {
      width: 100%;
      height: 18.37em;
      object-fit: contain;
      border-radius: 2px;
    }
  }
}

@media @tablet {
  .layout {
    margin: 0 75px;
    background: #fff;
    min-height: 100vh;
  }
  .image-carousel {
    width: 100%;
    img {
      width: 100%;
      height: 28.37em;
      object-fit: contain;
      border-radius: 2px;
    }
  }
}

@media @ipad {
  .layout {
    margin: 0 150px;
    background: #fff;
    min-height: 100vh;
  }

  .image-carousel {
    width: 100%;
    img {
      width: 100%;
      height: 28.37em;
      object-fit: contain;
      border-radius: 2px;
    }
  }
}

@media @desktop {
  .layout {
    margin: 0 25%;
    padding: 0 20px;
    background: #fff;
    min-height: 100vh;
    // display: none;
    border-left: 1px solid #e6e7e7;
    border-right: 1px solid #e6e7e7;
  }
  .header {
    z-index: 999;
    position: absolute;
    top: 0;
    background: white;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    border-bottom: 1px solid #cdcfce;
    width: 49.8%;
    margin-left: -20px;

  }
  .postDiv {
    height: fit-content;
    position: fixed;
    bottom: 0;
    width: 49.8% !important;
    padding: 20px 10px;
    border-top: solid 1px #cdcfce;
    background-image: #FFFFFF !important;
  }
  .headerPost {
    z-index: 999;
    position: absolute;
    top: 0;
    background: white;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    border-bottom: 1px solid #cdcfce;
    width: 49.8%;
    margin-left: -20px;
//   padding: 20px 0;
}
  .bottomNav {
    width: 49.8% !important;
    // max-width: 680px;
  }


  
  .image-carousel {
    width: 100%;
    img {
      width: 100%;
      height: 40em;
      object-fit: contain;
      border-radius: 2px;
    }
  }
}

.dark-btn {
  background: #000000;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 40px;
  border-radius: 4px;
  &:hover {
    background: #001529;
    color: #fff;
    opacity: 0.9;
  }
}
.ant-input {
  border-radius: 4px;
}
.ant-btn {
  border-radius: 4px;
}

@primary-color: #278977;